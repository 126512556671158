import axios from 'axios';

import useSWR from 'swr';
import { getApiUrl } from '@/helpers/api';
import { useToast } from '@/helpers/toast';

const useEstimateComps = (estimateUid) => {
  const url = `${getApiUrl('v2')}/estimate-comp?estimateUid=${estimateUid}`;
  const swr = useSWR(url, async () => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });
  return swr;
};

const usePutEstimateComp = () => {
  const { toast } = useToast();
  const putEstimateComp = async (compUid, payload) => {
    try {
      const url = `${getApiUrl('v2')}/estimate-comp/${compUid}`;
      const result = await axios.put(url, payload);
      return result.data;
    } catch (error) {
      toast({ title: 'Error', description: 'An error occurred updating comp', variant: 'destructive' });
      throw Error(error);
    }
  };

  return putEstimateComp;
};

const useDeleteEstimateComp = () => {
  const { toast } = useToast();
  const deleteEstimateComp = async (compUid) => {
    try {
      const url = `${getApiUrl('v2')}/estimate-comp/${compUid}`;
      const result = await axios.delete(url);
      return result.data;
    } catch (error) {
      toast({ title: 'Error', description: 'An error occurred deleting comp', variant: 'destructive' });
      throw Error(error);
    }
  };

  return deleteEstimateComp;
};

const usePostEstimateComp = () => {
  const { toast } = useToast();
  const postEstimateComp = async (payload) => {
    try {
      const url = `${getApiUrl('v2')}/estimate-comp`;
      const result = await axios.post(url, payload);
      return result.data;
    } catch (error) {
      toast({ title: 'Error', description: 'An error occurred adding comp', variant: 'destructive' });
      throw Error(error);
    }
  };

  return postEstimateComp;
};

export { useEstimateComps, usePutEstimateComp, useDeleteEstimateComp, usePostEstimateComp };
