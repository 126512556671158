import axios from 'axios';
import useSWR from 'swr';

import { getApiUrl } from '@/helpers/api';
import { useToast } from '@/helpers/toast';

const useEstimateIndirectCosts = (estimateUid) => {
  const url = `${getApiUrl('v2')}/estimate-indirect-cost?estimateUid=${estimateUid}`;
  const swr = useSWR(url, async () => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });
  return swr;
};

const usePutEstimateIndirectCost = () => {
  const { toast } = useToast();

  const putIndirectCost = async (estimateIndirectCostUid, payload) => {
    try {
      const url = `${getApiUrl('v2')}/estimate-indirect-cost/${estimateIndirectCostUid}`;
      const response = await axios.put(url, payload);
      return response.data;
    } catch (error) {
      toast({ title: 'Error', description: 'An error occurred updating indirect cost', variant: 'destructive' });
      throw Error(error);
    }
  };

  return putIndirectCost;
};

const usePostEstimateIndirectCost = () => {
  const { toast } = useToast();

  const postIndirectCost = async (payload) => {
    try {
      const url = `${getApiUrl('v2')}/estimate-indirect-cost`;
      const response = await axios.post(url, payload);
      return response.data;
    } catch (error) {
      toast({ title: 'Error', description: 'An error occurred creating indirect cost', variant: 'destructive' });
      throw Error(error);
    }
  };

  return postIndirectCost;
};

const useDeleteEstimateIndirectCost = () => {
  const { toast } = useToast();
  const deleteEstimateIndirectCost = async (estimateIndirectCostUid) => {
    try {
      const url = `${getApiUrl('v2')}/estimate-indirect-cost/${estimateIndirectCostUid}`;
      const result = await axios.delete(url);
      return result.data;
    } catch (error) {
      toast({ title: 'Error', description: 'An error occurred deleting indirect cost', variant: 'destructive' });
      throw Error(error);
    }
  };

  return deleteEstimateIndirectCost;
};

export { useEstimateIndirectCosts, usePutEstimateIndirectCost, usePostEstimateIndirectCost, useDeleteEstimateIndirectCost };
