import axios from 'axios';
import useSWR from 'swr';

import { getApiUrl } from '@/helpers/api';
import { useToast } from '@/helpers/toast';

const useEstimateCompCostBuckets = (estimateUid) => {
  const url = `${getApiUrl('v2')}/estimate-comp-cost-bucket?estimateUid=${estimateUid}`;
  const swr = useSWR(url, async () => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });
  return swr;
};

const usePutEstimateCompCostBucket = () => {
  const { toast } = useToast();
  const putEstimateCompCostBucket = async (estimateCompCostBucketUid, payload) => {
    try {
      const url = `${getApiUrl('v2')}/estimate-comp-cost-bucket/${estimateCompCostBucketUid}`;
      const result = await axios.put(url, payload);
      return result.data;
    } catch (error) {
      toast({ title: 'Error', description: 'An error occurred updating comp cost bucket', variant: 'destructive' });
      throw Error(error);
    }
  };

  return putEstimateCompCostBucket;
};

export { useEstimateCompCostBuckets, usePutEstimateCompCostBucket };
