import axios from 'axios';
import useSWR from 'swr';

import { getApiUrl } from '@/helpers/api';
import { useToast } from '@/helpers/toast';

const useEstimateCompCostFields = (estimateUid) => {
  const url = `${getApiUrl('v2')}/estimate-comp-cost-field?estimateUid=${estimateUid}`;
  const swr = useSWR(url, async () => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });
  return swr;
};

const usePutEstimateCompCostField = () => {
  const { toast } = useToast();
  const putEstimateCompCostField = async (estimateCompCostFieldUid, payload) => {
    try {
      const url = `${getApiUrl('v2')}/estimate-comp-cost-field/${estimateCompCostFieldUid}`;
      const result = await axios.put(url, payload);
      return result.data;
    } catch (error) {
      toast({ title: 'Error', description: 'An error occurred updating comp cost field', variant: 'destructive' });
      throw Error(error);
    }
  };

  return putEstimateCompCostField;
};

export { useEstimateCompCostFields, usePutEstimateCompCostField };
